<template>
     <div class="my_contract">
          <el-tabs v-model="activeName_f" type="card" class="demo-tabs" @tab-click="handleClick">
               <el-tab-pane label="买方合约订单" name="purchase"></el-tab-pane>
               <el-tab-pane label="卖方合约订单" name="supplier"></el-tab-pane>
          </el-tabs>

          <div class="zone1">
               <div class="layer2 flex-row">
                    <div class="layer3 flex-col justify-between">
                         <div class="mod4">
                              <el-tabs v-model="state" class="demo-tabs" @tab-click="handleClick_s">
                                   <el-tab-pane label="全部状态" name="0"></el-tab-pane>
                                   <el-tab-pane label="待确认" name="10"></el-tab-pane>
                                   <el-tab-pane label="已达成" name="20"></el-tab-pane>
                                   <el-tab-pane label="已失效" name="30"></el-tab-pane>
                              </el-tabs>
                         </div>
                    </div>
                    <div class="layer4 flex-col">
                         <input type="text" :placeholder="`合约单号/询盘标题/${activeName_f=='purchase'?'卖方':'买方'}`"
                              v-model="keyword">
                    </div>
                    <div class="layer5 flex-col" @click="confirm">
                         <span class="word6">搜索</span>
                    </div>
                    <div class="layer6 flex-col" @click="reset">
                         <span class="txt3">重置</span>
                    </div>
               </div>
          </div>

          <div class="zone2">
               <div class="layer7 flex-col">
                    <div class="outer71 flex-row">
                         <span class="info11">合约产品</span>
                         <span class="word71">{{activeName_f=='purchase'?'卖方':'买方'}}</span>
                         <span class="info21">合约金额(元)</span>
                         <span class="word81">对应询盘</span>
                         <span class="word91">状态</span>
                         <span class="info31">操作</span>
                    </div>
               </div>
               <div v-if="list.length">
                    <div class="layer12 flex-col" v-for="(item,index) in list" :key="index">
                         <div class="section5 flex-col">
                              <div class="box3 flex-col">
                                   <div class="group6 flex-row justify-between">
                                        <span class="info11">合约单号：{{item.contractSn}}</span>
                                        <span class="info12">{{item.createTime}}</span>
                                   </div>
                              </div>

                              <el-table :data="[item]" style="width: 100%" :show-header="false">
                                   <el-table-column width="70">
                                        <template #default="scope">
                                             <div>
                                                  <img class="img22" :src="scope.row.productImage"
                                                       v-if="scope.row.productImage" />
                                                  <img class="img22" src="@/assets/inquiry/inq_icon4.png" v-else>
                                             </div>
                                        </template>
                                   </el-table-column>

                                   <el-table-column width="200">
                                        <template #default="scope">
                                             <div class="bd1 flex-col justify-between">
                                                  <span class="paragraph2">{{scope.row.productName}}</span>
                                                  <div class="bd21">
                                                       <span class="txt41">等共</span>
                                                       <span class="txt51">{{scope.row.productNum}}</span>
                                                       <span class="txt41">件</span>
                                                  </div>
                                             </div>
                                        </template>
                                   </el-table-column>
                                   <el-table-column width="120" prop="enterpriseName" align="center" />
                                   <el-table-column width="130" prop="contractAmount" align="center" />
                                   <el-table-column width="180" align="center">
                                        <template #default="scope">
                                             <span class="infoBox5"
                                                  @click="seeInquiry(scope.row.inquireId)">{{scope.row.title}}&gt;&gt;</span>
                                        </template>
                                   </el-table-column>
                                   <el-table-column width="110" prop="stateValue" align="center" />
                                   <el-table-column width="160" align="center">
                                        <template #default="scope">
                                             <div class="flex_column_center_center">
                                                  <span class="paragraph3"
                                                       v-if="scope.row.state==10&&activeName_f=='purchase'"
                                                       @click="seeContract(scope.row.contractId)">去确认</span>
                                                  <span class="paragraph3"
                                                       @click="seeContract(scope.row.contractId)">查看详情</span>
                                                  <span class="paragraph3"
                                                       @click="toSeeQuotation(scope.row.quoteId)">查看报价</span>
                                                  <span class="paragraph3" v-if="scope.row.state!=10"
                                                       @click="delContract(scope.row,contractId)">删除</span>
                                             </div>
                                        </template>
                                   </el-table-column>
                              </el-table>

                         </div>
                    </div>

                    <!-- 分页 -->
                    <div class="flex_row_center_center sld_pagination">
                         <el-pagination @current-change="handleCurrentChange" v-model:currentPage="page.current"
                              :page-size="page.pageSize" layout="prev, pager, next, jumper" :total="page.total"
                              :hide-on-single-page="true">
                         </el-pagination>
                    </div>
               </div>
               <div class="inquiry_empty flex_column_center_center" v-else>
                    <img src="@/assets/inquiry/inq_empty.png" alt="">
                    <p>暂无报价</p>
               </div>
          </div>
     </div>
</template>


<script>
     import { ref, reactive, getCurrentInstance, toRefs, onMounted } from 'vue'
     import { useRouter, useRoute } from 'vue-router'
     export default {
          setup() {
               const router = useRouter()
               const route = useRoute()
               const activeName_f = ref('purchase')

               const searchDTO = reactive({
                    keyword: '',
                    state: 0
               })

               const contractList = reactive({
                    list: [],
                    page: {}
               })



               const { proxy } = getCurrentInstance()

               const page = reactive({
                    current: 1,
                    pageSize: 10,
                    total: 20
               })

               const handleClick = (tab, event) => {
                    getContractList(activeName_f.value)
               }

               const handleClick_s = (tab, event) => {
                    getContractList(activeName_f.value)
               }

               const confirm = () => {
                    getContractList(activeName_f.value)
               }

               const reset = () => {
                    searchDTO.keyword = ''
                    getContractList(activeName_f.value)
               }

               const getContractList = (type) => {

                    let param = {}

                    if (searchDTO.state != 0) {
                         param.state = searchDTO.state
                    } else {
                         delete param.state
                    }

                    if (searchDTO.keyword) {
                         param.keyword = searchDTO.keyword
                    } else {
                         delete param.keyword
                    }




                    proxy.$get(`/v3/business/front/${type}Inquire/contractList`, param).then(res => {
                         if (res.state == 200) {
                              contractList.list = res.data.list
                              contractList.page = res.data.pagination
                         }
                    })
               }

               const toSeeQuotation = (quoteId) => {
                    let path
                    if (activeName_f.value == 'supplier') {
                         path = '/inquiry/supplier/quo_info'
                    } else {
                         path = '/inquiry/purchaser/quo_info'
                    }


                    router.push({
                         path,
                         query: {
                              quoteId
                         }
                    })
               }

               const seeInquiry = (inquireId) => {
                    let path
                    if (activeName_f.value == 'supplier') {
                         path = '/inquiry/supplier/inq_info'
                    } else {
                         path = '/inquiry/purchaser/inq_info'
                    }


                    router.push({
                         path,
                         query: {
                              inquireId
                         }
                    })
               }

               const delContract = contractId => {
                    proxy.$confirm('确认删除该询盘？删除后数据不可恢复。', {
                         confirmButtonText: '确定',
                         cancelButtonText: '取消',
                         type: 'warning',
                         center: true
                    }).then(res => {
                         let url = activeName_f.value == 'supplier' ? 'v3/business/front/supplierInquire/deleteContract' : 'v3/business/front/purchaseInquire/deleteContract'

                         proxy.$post(url, { contractId }).then(res => {
                              if (res.state == 200) {
                                   ElMessage.success(res.msg)
                              }
                         })
                    })
               }

               const seeContract = (contractId) => {
                    let path = activeName_f.value == 'supplier' ? '/inquiry/supplier/contract_info' : '/inquiry/purchaser/contract_info'

                    router.push({
                         path,
                         query: {
                              contractId
                         }
                    })
               }

               onMounted(() => {
                    if (route.query.tab) {
                         activeName_f.value = route.query.tab
                    }
                    getContractList(activeName_f.value)
               })


               return {
                    activeName_f,
                    handleClick,
                    handleClick_s,
                    page,
                    ...toRefs(searchDTO),
                    ...toRefs(contractList),
                    confirm,
                    reset,
                    toSeeQuotation,
                    delContract,
                    seeContract,
                    seeInquiry
               }
          }
     }
</script>


<style lang="scss">
     .my_contract {
          .el-tabs__item {
               font-size: 16px;
               font-family: Source Han Sans CN;
               font-weight: 500;

          }

          .is-active {
               color: #285FDE;
          }
     }
</style>

<style lang="scss" scoped>
     input {
          height: 32px;
          border: none;
          outline: none;
     }

     .my_contract {
          float: left;
          width: 1015px;
          background-color: #fff;
          margin-left: 15px;
          padding: 20px;

          .zone1 {

               .layer2 {
                    margin-top: 25px;

                    .layer3 {
                         width: 307px;
                         height: 27px;
                         margin-top: 7px;

                         .mod4 {
                              width: 336px;

                              font-size: 0;
                              font-family: SourceHanSansCN-Medium;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 15px;
                              margin-left: 1px;



                         }
                    }

                    .layer4 {
                         border-radius: 4px;
                         height: 32px;
                         border: 1px solid rgba(191, 191, 191, 1);
                         margin-left: 255px;
                         width: 248px;
                         padding: 0 10px;
                    }

                    .layer5 {
                         cursor: pointer;
                         background-color: rgba(40, 95, 222, 1);
                         border-radius: 4px;
                         height: 32px;
                         margin-left: 14px;
                         width: 68px;

                         .word6 {
                              color: rgba(255, 255, 255, 1);
                              font-size: 14px;
                              line-height: 14px;
                              display: block;
                              margin: 9px 0 0 19px;
                         }
                    }

                    .layer6 {
                         cursor: pointer;
                         border-radius: 4px;
                         height: 32px;
                         border: 1px solid rgba(40, 95, 222, 1);
                         margin-left: 20px;
                         width: 68px;

                         .txt3 {
                              color: rgba(40, 95, 222, 1);
                              font-size: 14px;
                              line-height: 14px;
                              display: block;
                              margin: 9px 0 0 19px;
                         }
                    }
               }
          }

          .zone2 {

               .layer7 {
                    background-color: rgba(240, 242, 246, 1);
                    height: 45px;
                    margin-top: 31px;

                    .outer71 {
                         height: 16px;
                         margin: 16px 0 0 101px;

                         .info11 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              line-height: 14px;
                              margin-top: 1px;
                              display: block;
                         }

                         .word71 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              line-height: 14px;
                              margin-left: 160px;
                              display: block;
                         }

                         .info21 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              line-height: 16px;
                              margin-left: 73px;
                              display: block;
                         }

                         .word81 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              line-height: 14px;
                              display: block;
                              margin: 1px 0 0 78px;
                         }

                         .word91 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              line-height: 14px;
                              margin-left: 108px;
                              display: block;
                         }

                         .info31 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 14px;
                              line-height: 14px;
                              display: block;
                              margin: 1px 0 0 112px;
                         }
                    }
               }


               .layer12 {
                    border: 1px solid rgba(220, 220, 220, 1);
                    margin-top: 15px;

                    .section5 {
                         margin-top: 1px;

                         .box3 {
                              background-color: rgba(247, 248, 250, 1);
                              height: 36px;
                              margin-left: 1px;

                              .group6 {
                                   height: 11px;
                                   margin: 12px 0 0 10px;

                                   .info11 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 12px;
                                        line-height: 12px;
                                        display: block;
                                        margin-right: 10px;
                                   }

                                   .info12 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 12px;
                                        line-height: 12px;
                                        display: block;
                                   }
                              }
                         }

                         .img22 {
                              width: 50px;
                              height: 50px;
                         }

                         .bd1 {

                              .paragraph2 {
                                   color: rgba(51, 51, 51, 1);
                                   font-size: 12px;
                                   display: block;
                              }

                              .bd21 {
                                   margin-top: 7px;

                                   .txt41 {
                                        color: rgba(102, 102, 102, 1);
                                        font-size: 12px;
                                   }

                                   .txt51 {
                                        color: rgba(40, 95, 222, 1);
                                        font-size: 12px;
                                   }
                              }
                         }

                         .infoBox5 {
                              color: rgba(40, 95, 222, 1);
                              font-size: 12px;
                              cursor: pointer;
                         }

                         .paragraph3 {
                              color: rgba(51, 51, 51, 1);
                              font-size: 12px;
                              line-height: 28px;
                              display: block;
                              cursor: pointer;

                              &:hover {
                                   color: rgba(40, 95, 222, 1);
                              }
                         }


                    }
               }
          }

          .inquiry_empty {

               padding-top: 140px;
               padding-bottom: 200px;

               img {
                    width: 120px;
                    height: 119px;
               }

               p {
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 34px;
               }
          }

     }
</style>